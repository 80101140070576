<template>
  <div>
    <div class="stu-attestation-analysis" v-if="info.siteId && info.joinedCategoryCount != 0">
      <div class="cont train">
        <div class="train-l">
          <div class="train-l-top">
            <div class="train-l-top-t">
              <div class="tit">已练习/总分类数量:</div>
              <div class="num">{{info.joinedCategoryCount}}/{{info.categoryTotalCount}}</div>
            </div>
            <div class="progress-bj mt20"><div class="progress-val" :style="{width:(info.joinedCategoryCount / info.categoryTotalCount * 100) + '%'}"></div></div>
          </div>
          <div class="train-l-bot mt20">
            <div>
              <div class="num">{{ info.trainUsetime }}</div>
              <div>总练习时长</div>
            </div>
            <div>
              <div class="num">{{ info.trainCount }}</div>
              <div>总练习题数</div>
            </div>
          </div>
        </div>
        <div class="train-r">
          <div class="cont-top">
            <div class="tit">各教材练习情况</div>
            <div class="cont-top-r">
              <span class="legend"></span>
              <p>练习数量</p>
            </div>
          </div>
          <div id="categoryBar" style="width: 100%; height: 190px"></div>
        </div>
      </div>
      <div class="cont mt10 category">
        <div class="tit">查看各教材练习分析</div>
        <div class="category-list mt20">
          <div :class="curCategoryId == item.category_id ? 'active' : ''" v-for="item in info.categoryList" :key="item.id" @click="changeCategory(item)">{{ item.fullName }}</div>
        </div>
        <div class="category-stat">
          <div class="category-stat-info flex4">
            <div>
              <div class="num">{{ categoryInfo.trainCount }}</div>
              <div>总练习数</div>
            </div>
            <div>
              <div class="num">{{ categoryInfo.rightCount }}</div>
              <div>答对数</div>
            </div>
            <div>
              <div class="num">{{ categoryInfo.wrongCount }}</div>
              <div>答错数</div>
            </div>
            <div>
              <div class="num">{{ categoryInfo.rightRate }}</div>
              <div>正确率</div>
            </div>
          </div>
          <div class="category-stat-info flex2">
            <div>
              <div class="num">{{ categoryInfo.mapTree && categoryInfo.mapTree.length }}</div>
              <div>章节总数</div>
            </div>
            <div>
              <div class="num">{{ categoryInfo.categoryDataCount }}</div>
              <div>总题数</div>
            </div>
          </div>
        </div>
      </div>
      <!--    <div class="mt10 cont-s">-->
      <!--      <div class="cont">-->
      <!--        <div class="cont-top">-->
      <!--          <div class="tit">近期课后练习分数情况</div>-->
      <!--          <div class="cont-top-r">-->
      <!--            <span class="legend"></span>-->
      <!--            <p>课后练习分数</p>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--        <div></div>-->
      <!--      </div>-->
      <!--      <div class="cont">-->
      <!--        <div class="cont-top">-->
      <!--          <div class="tit">近期模考分数情况</div>-->
      <!--          <div class="cont-top-r">-->
      <!--            <div>-->
      <!--              <span class="legend legend_g"></span>-->
      <!--              <p>自测模考分数</p>-->
      <!--            </div>-->
      <!--            <div>-->
      <!--              <span class="legend"></span>-->
      <!--              <p>模拟考试分数</p>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--        <div></div>-->
      <!--      </div>-->
      <!--    </div>-->
      <div class="cont mt10">
        <div class="cont-top">
          <div class="tit">近期练习数量对比</div>
          <div class="cont-top-r">
            <!--          <div>-->
            <!--            <span class="legend legend_g"></span>-->
            <!--            <p>班级平均练习数量</p>-->
            <!--          </div>-->
            <div>
              <span class="legend"></span>
              <p>我的练习数量</p>
            </div>
          </div>
        </div>
        <div id="category-day-chart" style="width: 100%;height: 500px;"></div>
      </div>
      <div class="cont-s mt10">
        <div class="cont">
          <div class="tit">涵盖教材章节正确率:</div>
          <div class="absorb-l-cont">
            <div class="absorb-l-cont-item" v-for="item in categoryInfo.mapTree" :key="item.id">
              <div class="absorb-l-cont-item-tit">{{ item.node.name }}</div>
              <div class="absorb-l-cont-item-list">
                <div class="absorb-l-cont-item-list-item" v-for="sItem in item.children" :key="sItem.id">
                  <div class="absorb-l-cont-item-list-item-name">{{ sItem.node.name }}</div>
                  <div class="absorb-l-cont-item-list-item-progress">
                    <div class="progress-bj"><div class="progress-val" :style="{width:(sItem.data_count !=0 ? (sItem.right_data_count/sItem.data_count*100) : 0) + '%'}"></div></div>
                    <div class="absorb-l-cont-item-list-item-progress-nr">共{{sItem.data_count}}题，正确率：{{ sItem.data_count !=0 ? (sItem.right_data_count/sItem.data_count*100).toFixed(2) : 0 }}%</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="cont">
          <div class="tit">知识点掌握分析:</div>
          <div class="absorb-r-cont" v-if="categoryInfo.keypointList && categoryInfo.keypointList.length">
            <div class="absorb-r-cont-item" v-for="item in categoryInfo.keypointList" :key="item.label_id">
              <div class="absorb-r-cont-item-name">{{ item.label_name }}</div>
              <div class="absorb-r-cont-item-progress">
                <div class="progress-bj"><div class="progress-val" :style="{width:(item.total != '0' ? (item.right_count / item.total * 100) : 0) + '%'}"></div></div>
                <div class="absorb-r-cont-item-progress-nr">掌握程度：{{ item.total != '0' ? (item.right_count / item.total * 100).toFixed(2) : 0 }}%</div>
                <Button type="primary" @click="goTrain(item)"  v-if="!userId">进入练习</Button>
              </div>
            </div>
          </div>
          <div class="no-data" style="padding: 42px 0;"  v-else>
            <img src="../../../assets/images/new_icon/kong.png" width="355" height="190">
            <p>空空如也...</p>
          </div>
        </div>

      </div>
<!--      <div class="cont mt10">-->
<!--        <div class="tit">多次错题知识点(点击知识点进行练习):</div>-->
<!--        <div class="nodes" v-if="categoryInfo.userMulWrongLabelKeypointList && categoryInfo.userMulWrongLabelKeypointList.length">-->
<!--          <p v-for="item in categoryInfo.userMulWrongLabelKeypointList" :key="item.id" @click="goTrain(item)">{{ item.name }}</p>-->
<!--        </div>-->
<!--        <div class="nodes" v-else>-->
<!--          暂无数据-->
<!--        </div>-->
<!--      </div>-->
      <div class="cont-s mt10">
        <div class="cont">
          <div class="cont-top">
            <div class="tit">题型正确率:</div>
            <div class="cont-top-r">
              <div>
                <span class="legend"></span>
                <p>正确率</p>
              </div>
            </div>
          </div>
          <div class="absorb-r-cont" style="height: 220px" v-if="categoryInfo.dataTypeList && categoryInfo.dataTypeList.length">
            <div class="absorb-r-cont-item" v-for="item in categoryInfo.dataTypeList" :key="item.type">
              <div class="absorb-r-cont-item-name">{{ item.name }}</div>
              <div class="absorb-r-cont-item-progress">
                <div class="progress-bj"><div class="progress-val" :style="{width:(item.data_count != '0' ? (item.right_count / item.data_count * 100) : 0) + '%'}"></div></div>
                <div class="absorb-r-cont-item-progress-nr">正确率：{{ item.data_count != '0' ? (item.right_count / item.data_count * 100).toFixed(2) : 0 }}%</div>
              </div>
            </div>
          </div>
<!--          <div id="type-rate" style="width: 100%; height: 200px"></div>-->
        </div>
        <div class="cont">
          <div class="tit">练习题型分布情况</div>
          <div style="width: 100%;height: 200px" id="pie"></div>
        </div>
      </div>
    </div>
    <div class="no-data" v-if="info.siteId && info.joinedCategoryCount == 0">
      <img src="../../../assets/images/new_icon/kong.png" width="355" height="190">
      <p>您还没有练习过教材题...</p>
    </div>
  </div>

</template>

<script>
import util from "@/utils/tools.js";
export default {
  name: "stuAttestationAnalysis",
  data(){
    return {
      curCategoryId:'',
      info:{},
      categoryInfo:{},
      pageStatus:true,
    }
  },
  props:{
    userId:{
      type:String,
      default:'',
    }
  },
  mounted(){
    this.getData();
  },
  beforeDestroy(){
    this.pageStatus = false;
  },
  methods:{
    getData(){
      let params = {
        category_type:'1',
      }
      // params.site_id = -1;
      if(this.userId){
        params.user_id = this.userId;
      }
      this.api.analysis.datasetTrainStatByUser(params).then((res)=>{
        this.info = res;

        let dateObj = util.timeFormate(res.trainUsetime);

        let minutes = dateObj.minutes;
        let seconds = dateObj.seconds;
        minutes = minutes > 9 ? '' + minutes : '0' + minutes;
        seconds = seconds > 9 ? '' + seconds : '0' + seconds;
        let hours = 0;

        if(dateObj.days){
          hours = dateObj.days * 24 + dateObj.hours
        }

        this.info.trainUsetime = (hours ? hours : '00') + ':' + (dateObj.minutes ? (minutes) : '00') + ':' + seconds;
        if(this.info.categoryList.length){
          this.info.categoryList.forEach((item)=>{
            let name = item.categoryList.map((sItme)=>{
              return sItme.name;
            }).join('-');
            item.fullName = name;
          })

          this.curCategoryId = this.info.categoryList[0].category_id;
          this.$nextTick(()=>{
            if(this.pageStatus){
              this.setCatergoryBarOption();
            }

          })

          this.getCategoryData();
        }

      })
    },
    setCatergoryBarOption(){
      let chartDom = document.getElementById('categoryBar');
      let myChart = this.$echarts.init(chartDom);
      let option;

      let names = [];
      let counts = [];
      this.info.categoryList.forEach((item)=>{
        names.push(item.fullName);
        counts.push(item.train_count);
      });

      option = {
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          // data: ['Email', 'Union Ads', 'Video Ads', 'Direct', 'Search Engine'],
          show:false
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '0',
          top:'20px',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          // boundaryGap: false,
          data: names
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '练习数量',
            type: 'bar',
            stack: 'Total',
            data: counts,
            barMaxWidth:'60'
          },
        ]
      };
      option && myChart.setOption(option);

      window.addEventListener('resize', () => {
        if (myChart) {
          myChart.resize()
        }
      })
    },
    getCategoryData(){
      let params = {
        category_id:this.curCategoryId
      }
      // params.site_id = -1;
      if(this.userId){
        params.user_id = this.userId;
      }
      this.api.analysis.statByUserAndCategory(params).then((res)=>{

        this.categoryInfo = res;
        if(this.pageStatus){
          this.setCatergoryBarOption();
          this.setCategoryDayChart();
          // this.setTypeRateChart();
          this.setPieOption();
        }
      })
    },
    setCategoryDayChart(){
      let chartDom = document.getElementById('category-day-chart');
      let myChart = this.$echarts.init(chartDom);
      let option;

      let names = [];
      let counts = [];
      this.categoryInfo.statUserDayList.forEach((res)=>{
        names.push(res.date);
        counts.push(res.count);
      });

      option = {
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          // data: ['Email', 'Union Ads', 'Video Ads', 'Direct', 'Search Engine'],
          show:false
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          // boundaryGap: false,
          //data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
          data: names
        },
        yAxis: {
          type: 'value'
        },
        series: [
          // {
          //   name: 'Email',
          //   type: 'line',
          //   stack: 'Total',
          //   data: [120, 132, 101, 134, 90, 230, 210]
          // },
          {
            name: '我的练习数量',
            type: 'line',
            stack: 'Total',
            data: counts
          },
        ]
      };

      option && myChart.setOption(option);

      window.addEventListener('resize', () => {
        if (myChart) {
          myChart.resize()
        }
      })

    },
    // setTypeRateChart(){
    //   let chartDom = document.getElementById('type-rate');
    //   let myChart = this.$echarts.init(chartDom);
    //   let option;
    //
    //   let names = [];
    //   let counts = [];
    //   this.categoryInfo.dataTypeList.forEach((res)=>{
    //     names.push(res.name);
    //     counts.push((res.right_count/res.data_count * 100).toFixed(2));
    //   });
    //
    //   option = {
    //     tooltip: {
    //       trigger: 'axis'
    //     },
    //     legend: {
    //       // data: ['Email', 'Union Ads', 'Video Ads', 'Direct', 'Search Engine'],
    //       show:false
    //     },
    //     grid: {
    //       left: '3%',
    //       right: '4%',
    //       bottom: '0',
    //       top:'20px',
    //       containLabel: true
    //     },
    //     xAxis: {
    //       type: 'category',
    //       // boundaryGap: false,
    //       //data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
    //       data: names
    //     },
    //     yAxis: {
    //       type: 'value'
    //     },
    //     series: [
    //       // {
    //       //   name: 'Email',
    //       //   type: 'line',
    //       //   stack: 'Total',
    //       //   data: [120, 132, 101, 134, 90, 230, 210]
    //       // },
    //       {
    //         name: '正确率',
    //         type: 'bar',
    //         stack: 'Total',
    //         data: counts,
    //         barMaxWidth:'60'
    //       },
    //     ]
    //   };
    //
    //   option && myChart.setOption(option);
    //
    //   window.addEventListener('resize', () => {
    //     if (myChart) {
    //       myChart.resize()
    //     }
    //   })
    //
    // },
    setPieOption(){
      var chartDom = document.getElementById('pie');
      var myChart = this.$echarts.init(chartDom);
      var option;

      let data = [];
      this.categoryInfo.dataTypeList.forEach((res)=>{
        data.push({
          name:res.name,
          value:res.data_count
        })
      });

      option = {
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: 'left',
          top: 'center'
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 40,
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: true
            },
            data: data
          }
        ]
      };

      option && myChart.setOption(option);
      window.addEventListener('resize', () => {
        if (myChart) {
          myChart.resize()
        }
      })
    },
    changeCategory(data){
      this.curCategoryId = data.category_id;
      this.getCategoryData();
    },
    goTrain(data){
      this.$router.push({
        path:'/trainingcamp/trainDetail',
        query:{
          id:this.curCategoryId,
          dataSort:'1',   //0每日一练1全部
          data_type:7,
          generate_id:data.label_id
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.stu-attestation-analysis{
  padding-bottom: 20px;
  font-size: 14px;
  .cont{
    padding: 20px;
    background-color: #FFFFFF;
  }
  .train{
    display: flex;
    justify-content: flex-start;
    .train-l{
      margin-right: 25px;
      flex: 2;
      .train-l-top{
        padding: 20px;
        height: 98px;
        background: #F6F7FA;
        border-radius: 6px;
        .train-l-top-t{
          display: flex;
          justify-content: space-between;
          align-items: center;
          .num{
            font-size: 16px;
            font-weight: bold;
            color: #4A79FF;
          }
        }
      }
      .train-l-bot{
        padding: 20px;
        height: 98px;
        background: #F6F7FA;
        border-radius: 6px;
        display: flex;
        justify-content: flex-start;
        >div{
          flex: 1;
          text-align: center;
          color: #666666;
          .num{
            margin-bottom: 6px;
            font-size: 20px;
            color: #333333;
          }
        }
      }
    }
    .train-r{
      flex: 5;
    }
  }
  .category{
    .category-list{
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      color: #333333;
      >div{
        //margin-right: 28px;
        //margin-bottom: 10px;
        //cursor: pointer;
        margin-right: 10px;
        margin-bottom: 10px;
        padding: 0 10px;
        height: 42px;
        background: #F6F7FA;
        border-radius: 6px;
        cursor: pointer;
        font-size: 14px;
        font-weight: bold;
        color: #333333;
        text-align: center;
        line-height: 42px;
      }
      .active{
        //font-weight: bold;
        //color: #4A79FF;
        background: #4A79FF;
        color: #FFFFFF;
      }
    }
    .category-stat{
      display: flex;
      justify-content: flex-start;
      .category-stat-info{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 98px;
        background: #F6F7FA;
        border-radius: 6px;
        >div{
          flex: 1;
          text-align: center;
          color: #666666;
          .num{
            margin-bottom: 6px;
            font-size: 20px;
            color: #333333;
          }
        }
      }
      .flex4{
        margin-right: 30px;
        flex: 4;
      }
      .flex2{
        flex: 2;
      }
    }
  }
  .absorb-l-cont{
    padding: 20px;
    height: 315px;
    overflow-y: auto;
    .absorb-l-cont-item{
      .absorb-l-cont-item-tit{
        font-weight: bold;
      }
      .absorb-l-cont-item-list{
        padding-left: 50px;
        margin-bottom: 20px;
        .absorb-l-cont-item-list-item{
          margin-top: 20px;
          .absorb-l-cont-item-list-item-progress{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .absorb-l-cont-item-list-item-progress-nr{
              margin-left: 16px;
              width: 240px;
            }
          }
        }
      }
    }
  }
  .absorb-r-cont{
    padding: 20px;
    height: 315px;
    overflow-y: auto;

    .absorb-r-cont-item{
      margin-bottom: 16px;
      .absorb-r-cont-item-name{
        margin-bottom: 5px;
      }
      .absorb-r-cont-item-progress{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .absorb-r-cont-item-progress-nr{
          margin-left: 16px;
          width: 140px;
        }
      }
    }
  }
  .nodes{
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    >p{
      margin-right: 10px;
      margin-bottom: 10px;
      padding: 0 10px;
      height: 28px;
      background: #EEEEEE;
      border-radius: 4px;
      line-height: 28px;
      font-size: 14px;
      color: #666666;
    }
  }
  .nodes-btn{
    margin-top: 20px;
    width: 112px;
    height: 30px;
    background: #4A79FF;
    border-radius: 4px;
    color: #FFFFFF;
    text-align: center;
    line-height: 30px;
    cursor: pointer;
  }
  .cont-s{
    display: flex;
    justify-content: flex-start;
    >div{
      flex: 1;
      margin-right: 16px;
    }
    >div:last-child{
      margin-right: 0;
    }
  }
  .cont-top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #333333;
    .cont-top-r{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      >div{
        margin-left: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
      .legend{
        margin-right: 5px;
        display: inline-block;
        width: 14px;
        height: 14px;
        background: #4A79FF;
        border-radius: 1px;
      }
      .legend_g{
        background: #5AB4BF;
      }
    }
  }
  .mt10{
    margin-top: 10px;
  }
  .mt20{
    margin-top: 20px;
  }
  .tit{
    font-size: 16px;
    font-weight: bold;
    color: #333333;
  }
  .progress-bj{
    flex: 1;
    height: 10px;
    background: #EEEEEE;
    border-radius: 5px;
    position: relative;
    .progress-val{
      width: 70%;
      height: 10px;
      background: #4A79FF;
      border-radius: 5px;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}
.no-data{
  padding: 100px 0;
  font-size: 14px;
  text-align: center;
  background-color: #FFFFFF;
}
</style>
